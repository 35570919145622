<template>
	<div class="section">
		<div class="container">
			<h1>Saved grille schedules</h1>
			<div class="columns is-multiline" v-if="!loading">
				<div class="column is-12 is-flex is-flex-wrap-wrap">
					<o-field label="Application" class="mx-2">
						<o-select v-model="filter.typeId" placeholder="All" v-if="options.types">
							<option :value="null">All</option>
							<option v-for="option in options.applications" :value="option.Id" :key="option.Id">
								{{ option.Name }}
							</option>
						</o-select>
					</o-field>
					<o-field label="Type" class="mx-2">
						<o-select v-model="filter.typeId" placeholder="All" v-if="options.types">
							<option :value="null">All</option>
							<option v-for="option in options.types" :value="option.Id" :key="option.Id">
								{{ option.Name }}
							</option>
						</o-select>
					</o-field>
					<o-field label="Style" class="mx-2">
						<o-select v-model="filter.styleId" placeholder="All" v-if="options.styles">
							<option :value="null">All</option>
							<option v-for="option in options.styles" :value="option.Id" :key="option.Id">
								{{ option.Name }}
							</option>
						</o-select>
					</o-field>
					<o-field label="Material" class="mx-2">
						<o-select v-model="filter.materialId" placeholder="All" v-if="options.materials">
							<option :value="null">All</option>
							<option v-for="option in options.materials" :value="option.Id" :key="option.Id">
								{{ option.Name }}
							</option>
						</o-select>
					</o-field>
					<o-field label="Finish" class="mx-2">
						<o-select v-model="filter.finish" placeholder="All" v-if="options.finishes">
							<option :value="null">All</option>
							<option v-for="option in options.finishes" :value="option" :key="option">
								{{ option }}
							</option>
						</o-select>
					</o-field>
					<o-field label="Frame" class="mx-2">
						<o-select v-model="filter.frameId" placeholder="All" v-if="options.frames">
							<option :value="null">All</option>
							<option v-for="option in options.frames" :value="option.Id" :key="option.Id">
								{{ option.Name }}
							</option>
						</o-select>
					</o-field>
				</div>
			</div>
			<div>
				<loading class="has-text-centered" v-if="loading"></loading>
				<div class="is-flex is-flex-wrap-wrap" v-if="!loading">
					<div
						class="is-flex flex-basis quarter-basis is-flex-grow-1"
						v-for="schedule in filteredSchedules"
						:key="schedule.Id">
						<div class="equal-height is-flex-grow-1 card mx-2 my-2">
							<div class="card-content">
								<div class="media">
									<div class="media-content">
										<p class="title is-4">{{ schedule.Name }}</p>
										<p class="subtitle is-6">
											{{ $moment.utc(schedule.CreatedOn).local().format("MMM D YYYY") }}
										</p>
									</div>
									<div class="media-right is-flex">
										<div v-if="schedule.QuoteId">
											<o-tooltip>
												<o-icon icon="link"></o-icon>
												<template v-slot:content>This has been linked to a Quote.</template>
											</o-tooltip>
										</div>
										<div>
											<o-tooltip>
												<span
													class="hover"
													@click="
														if (!schedule._specProcessing) {
															GetRapidSpec(schedule);
														}
													">
													Download Rapid Spec
													<o-icon v-if="!schedule._specProcessing" icon="file-download"></o-icon>
													<loading v-else></loading>
												</span>
												<template v-slot:content>
													Download the rapid spec for this schedule.
												</template>
											</o-tooltip>
										</div>
									</div>
								</div>
								<div class="content">
									<div v-for="grille in schedule.Grilles" :key="grille.Id">
										<!--<figure class="image is-48x48">
                                            <img class="is-rounded" :src="api + 'api/image/' + grille.GrilleStyle.Style.Src" />
                                        </figure>-->
										<div>
											{{ grille.GrilleStyle.Style.Name }}
											<span v-if="grille.GrilleFrame?.FrameId">
												- {{ grille.GrilleFrame.Frame.Name }}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<a class="card-footer-item" @click="SetQuote(schedule)">
									<span style="white-space: nowrap">
										<o-icon icon="plus" style="vertical-align: middle"></o-icon>
										Set as current schedule
									</span>
								</a>
								<a class="card-footer-item" @click="TryRemove(schedule)">
									<span style="white-space: nowrap">
										<o-icon icon="trash" style="vertical-align: middle"></o-icon>
										Delete
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	import { docExport } from "@/services/utils";

	export default {
		setup() {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $moment = inject("$moment");
			const $success = inject("$success");
			const $error = inject("$error");

			const api = ref(process.env.VUE_APP_API);
			const loading = ref(true);
			const schedules = ref([]);

			const options = ref({});
			const filter = ref({
				typeId: null,
				styleId: null,
				materialId: null,
				finish: null,
				frameId: null
			});

			const filteredSchedules = computed(() => {
				return schedules.value.filter(
					(x) =>
						(!filter.value.typeId || x.Grilles.some((g) => g.TypeId === filter.value.typeId)) &&
						(!filter.value.styleId ||
							x.Grilles.some((g) => g.GrilleStyle.StyleId === filter.value.styleId)) &&
						(!filter.value.materialId ||
							x.Grilles.some((g) => g.GrilleMaterial.MaterialId === filter.value.materialId)) &&
						(!filter.value.finish ||
							x.Grilles.some((g) => g.GrilleFinish.Finish.Name === filter.value.finish)) &&
						(!filter.value.frameId ||
							x.Grilles.some((g) => g.GrilleFrame?.FrameId === filter.value.frameId))
				);
			});

			const GetSchedules = async () => {
				try {
					var response = await $http.get("/api/grilleSchedule");
					schedules.value = response.data;
					return response;
				} catch (err) {
					$error("Failed to load grilles.");
					return Promise.reject(err);
				}
			};

			const GetGrilleTypes = () => {
				return $http.get("/api/grilleType");
			};
			const GetStyleTypes = () => {
				return $http.get("/api/grilleStyleType");
			};
			const GetMaterialTypes = () => {
				return $http.get("/api/grilleMaterialType");
			};
			const GetFinishTypes = () => {
				return $http.get("/api/grilleFinishType");
			};
			const GetFrameTypes = () => {
				return $http.get("/api/grilleFrameType");
			};
			const GetApplicationTypes = () => {
				return $http.get("/api/applicationType");
			};

			const GetOptions = async () => {
				try {
					const promises = [
						GetGrilleTypes(),
						GetStyleTypes(),
						GetMaterialTypes(),
						GetFinishTypes(),
						GetFrameTypes(),
						GetApplicationTypes()
					];
					var response = await Promise.all(promises);
					options.value.types = response[0].data.sort((a, b) => (a.Name > b.Name ? 1 : -1));
					options.value.styles = response[1].data.sort((a, b) => (a.Name > b.Name ? 1 : -1));
					options.value.materials = response[2].data.sort((a, b) => (a.Name > b.Name ? 1 : -1));
					options.value.finishes = response[3].data
						.sort((a, b) => (a.Name > b.Name ? 1 : -1))
						.map((x) => x.Name)
						.filter((val, i, self) => self.indexOf(val) === i);
					options.value.frames = response[4].data.sort((a, b) => (a.Name > b.Name ? 1 : -1));
					options.value.applications = response[5].data.sort((a, b) => (a.Name > b.Name ? 1 : -1));
					return response;
				} catch (err) {
					return Promise.reject(err);
				}
			};

			const GetRapidSpec = async (row) => {
				row._specProcessing = true;
				const grilles = [];
				for (var i in row.Grilles) {
					grilles.push(ToRFQGrille(row.Grilles[i]));
				}
				var response = await $http.post("/api/grilleSchedule/rapidspec", { grilles: grilles });
				docExport.CreateDocument(response.data, "Rapid Spec.docx");
				row._specProcessing = undefined;
			};

			const ToRFQGrille = (row) => {
				const grille = {
					Id: row.Id,
					Quantity: row.Quantity,
					TypeId: row.TypeId,
					Type: row.Type,
					GrilleFrame: row.GrilleFrame?.Frame || {},
					GrilleStyle: row.GrilleStyle.Style,
					GrilleMaterial: row.GrilleMaterial.Material,
					GrilleFinish: row.GrilleFinish.Finish,
					GrilleThickness: !row.GrilleThickness?.Thickness
						? {}
						: { ...row.GrilleThickness.Thickness },
					GrillePatterns:
						row.GrillePatterns.length == 0
							? {}
							: {
									...row.GrillePatterns[0],
									Name:
										row.GrillePatterns[0].Size.Size +
										" Open Air " +
										row.GrillePatterns[0].Detail.PercentOpen +
										"%",
									GrilleId: 0
							  },
					GrilleSizes: JSON.parse(JSON.stringify(row.GrilleSizes)),
					GrilleOption: { ...row.GrilleOption }
				};

				//clear IDs
				if (grille.GrilleOption.GrilleId) {
					grille.GrilleOption.GrilleId = 0;
				}
				if (grille.GrillePatterns.GrilleId) {
					grille.GrillePatterns.GrilleId = 0;
				}
				for (let i = 0; i < grille.GrilleSizes.length; i++) {
					const size = grille.GrilleSizes[i];
					if (size.GrilleId) {
						size.GrilleId = 0;
					}
				}

				return grille;
			};

			const SetQuote = (row) => {
				const grilles = [];
				for (var i in row.Grilles) {
					grilles.push(ToRFQGrille(row.Grilles[i]));
				}
				global.$store.dispatch("setGrilles", grilles);
				$success("Schedule has been set.");
				global.$router.push({ name: "rfq" });
			};

			const Remove = async (row) => {
				try {
					return await $http({
						method: "DELETE",
						url: "/api/grilleSchedule",
						headers: { "Content-Type": "application/json" },
						data: { Ids: [row.Id] }
					});
				} catch (err) {
					$error("Failed to remove.");
					return Promise.reject(err);
				}
			};

			const TryRemove = async (row) => {
				if (confirm("Are you sure you want to remove this saved schedule?")) {
					await Remove(row);
					const i = schedules.value.indexOf(row);
					schedules.value.splice(i, 1);
					$success("Removed schedule.");
				}
			};

			onMounted(async () => {
				await GetSchedules();
				await GetOptions();
				loading.value = false;
			});

			return {
				api,
				loading,
				schedules,
				filteredSchedules,
				filter,
				options,
				SetQuote,
				TryRemove,
				GetRapidSpec
			};
		}
	};
</script>
