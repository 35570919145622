<template>
    <div class="section">
        <div class="container dark">
            <h1>Current Schedule</h1>
            <loading v-if="loading"></loading>
            <form class="schedule-buttons">
                <o-button icon-left="plus" size="small" @click="$router.push({ name: 'rfq' });">Add another grille</o-button>
            </form>
            <ul class="centered" v-if="!loading">
                <li class="grille" v-for="grille in grilles" :key="grille.Id">
                    <figure class="preview">
                        <img :src="api + 'api/image/' + grille.GrilleStyle.Src" @load="Loaded" />
                        <figcaption>{{grille.GrilleStyle?.Name}}</figcaption>
                    </figure>
                    <dl class="grille-description">
                        <dt>TYPE</dt>
                        <dd>{{grille.Type?.Name}}</dd>
                        <dt>STYLE</dt>
                        <dd>{{grille.GrilleStyle?.Name}}</dd>
                        <dt>MATERIAL</dt>
                        <dd>{{grille.GrilleMaterial?.Name}}</dd>
                        <dt>FINISH</dt>
                        <dd>{{grille.GrilleFinish?.Name}}</dd>
                        <dt v-if="!IsPerforated(grille)">FRAME</dt>
                        <dd v-if="!IsPerforated(grille)">{{grille.GrilleFrame?.Name}}</dd>
                        <dt v-if="IsPerforated(grille)">THICKNESS</dt>
                        <dd v-if="IsPerforated(grille)">{{grille.GrilleThickness?.Name}}</dd>
                        <dt>QUANTITY</dt>
                        <dd>{{TotalAmount(grille)}}</dd>
                    </dl>
                    <dl class="plain-table">
                        <dt>SIZE</dt>
                        <dd>
                            <table>
                                <tr>
                                    <th>Measurement</th>
                                    <th>Dimensions</th>
                                    <th>Amount</th>
                                </tr>
                                <tr v-for="size in grille.GrilleSizes" :key="size">
                                    <td>{{size.MeasurementTypeId == 1 ? "Opening:" : "Overall:"}}</td>
                                    <td>{{size.Height}}&quot;H&nbsp;&#215;&nbsp;{{size.Length}}&quot;L</td>
                                    <td>
                                        {{size.Quantity}}
                                    </td>
                                </tr>
                            </table>
                        </dd>
                    </dl>
                    <form class="grille-buttons">
                        <o-button icon-left="pencil" @click="$router.push({ name: 'rfq' })">Edit</o-button>
                        <o-button icon-left="copy" @click="Duplicate(grille)">Duplicate</o-button>
                        <o-button icon-left="trash" @click="Delete(grille)">Delete</o-button>
                    </form>
                </li>
            </ul>
            <form class="schedule-buttons">
                <o-button icon-left="quote-right" variant="primary" @click="Send()">Request quote for this schedule</o-button>
                <o-button icon-left="file-download" :variant="specProcessing ? 'light' : 'primary'" :disabled="specProcessing" @click="GetRapidSpec()"><span v-if="!specProcessing">Download spec for this schedule</span><loading v-else></loading></o-button>
                <o-tooltip variant="primary" :active="!$store.getters.isLoggedIn">
                    <template v-slot:content>
                        You must login to save the schedule.
                    </template>
                    <o-button icon-left="save" variant="primary" :disabled="saving || !$store.getters.isLoggedIn" @click="Save()">Save schedule</o-button>
                </o-tooltip>
            </form>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import { Name, Contact } from "@/components/modal";
    import { docExport } from "@/services/utils";

    export default {
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const api = ref(process.env.VUE_APP_API);
            const loading = ref(true);
            const saving = ref(false);
            const specProcessing = ref(false);

            const options = ref({});
            const filter = ref({
                typeId: null,
                styleId: null,
                materialId: null,
                finish: null,
                frameId: null
            });

            const grilles = global.$store.getters.grilles;

            const IsPerforated = (grille) => {
                return grille.TypeId === 2;
            };

            const GetRapidSpec = async () => {
                specProcessing.value = true;
                var response = await $http.post("/api/grilleSchedule/rapidspec", { grilles: grilles });
                docExport.CreateDocument(response.data, "Rapid Spec.docx");
                specProcessing.value = false;
            };


            const Delete = (grille) => {
                const i = grilles.indexOf(grille);
                grilles.splice(i, 1);
                global.$store.dispatch("setGrilles", grilles);
            };

            const Edit = (row) => {
                const i = grilles.indexOf(row);
                grilles.splice(i, 1);
                global.$store.dispatch("setGrilles", grilles);
                sessionStorage.setItem("editGrille", JSON.stringify(row));

                global.$router.push({ name: "rfq" });
            };

            const Duplicate = (row) => {
                sessionStorage.setItem("editGrille", JSON.stringify(row));

                global.$router.push({ name: "rfq" });
            };

            const CleanCopy = () => {
                const copy = [...grilles];
                //clear IDs
                for (var index in copy) {
                    if (copy[index].GrilleOption.GrilleId) {
                        copy[index].GrilleOption.GrilleId = 0;
                    }
                    if (copy[index].GrillePatterns.GrilleId) {
                        copy[index].GrillePatterns.GrilleId = 0;
                    }

                    for (let i = 0; i < copy[index].GrilleSizes.length; i++) {
                        const size = copy[index].GrilleSizes[i];
                        if (size.GrilleId) {
                            size.GrilleId = 0;
                        }
                    }
                }

                return copy;
            };

            const PostSchedule = (name) => {
                return $http.post("/api/grilleSchedule", { name: name, grilles: CleanCopy() });
            };

            const TrySave = async (result) => {
                try {
                    saving.value = true;
                    await PostSchedule(result.name);
                    result.close();
                    global.$store.dispatch("setGrilles", []);
                    $success("Grille Schedule saved!");
                } catch (err) {
                    $error("Failed to save");
                }
            };

            const Save = () => {
                global.$oruga.modal.open({
                    parent: this,
                    component: Name,
                    trapFocus: true,
                    active: true,
                    props: {
                        title: "Name Grille Schedule",
                        description: "Set a name to easily find this again.",
                    },
                    events: {
                        save: (result) => {
                            TrySave(result);
                        },
                        close: () => {
                            saving.value = false;
                        }
                    }
                });
            };

            const SendQuote = (grilles, contact) => {
                return $http.post("/api/quote", { grilles: CleanCopy(), contact: contact });
            };

            const TrySend = async (result) => {
                try {
                    result.loading.value = true;
                    await SendQuote(result.grilles, result.contact);
                    result.close();
                    global.$store.dispatch("setGrilles", []);
                    $success("Quote has been submitted!");
                } catch (err) {
                    result.loading.value = false;
                    $error("Failed to send quote");
                }
            };

            const Send = () => {
                global.$oruga.modal.open({
                    parent: this,
                    component: Contact,
                    trapFocus: true,
                    active: true,
                    props: {
                        title: "Request a Quote",
                        description: "Let us know how to reach you.",
                        grilles: grilles
                    },
                    events: {
                        send: (result) => {
                            TrySend(result);
                        }
                    }
                });
            };

            const TotalAmount = (row) => {
                let total = 0;
                for (const size of row.GrilleSizes) {
                    if (size.Quantity) {
                        total += parseInt(size.Quantity);
                    }
                }
                return total;
            };

            onMounted(async () => {
                loading.value = false;
            });

            return {
                api,
                loading,
                saving,
                specProcessing,
                grilles,
                filter,
                options,
                Delete,
                Edit,
                Duplicate,
                Save,
                Send,
                GetRapidSpec,
                TotalAmount,
                IsPerforated
            };
        }
    }
</script>
<style lang="scss">
    @import "~bulma/sass/utilities/_all.sass";

    @mixin dsc-title {
        font-size: 0.8rem;
        font-weight: bold;
        color: #{$info} !important;
    }

    $break-medium: 38rem;
    $break-large: 64rem;
    $column-gap: 2.5em;

    .container.dark {
        background-color: #f8f8f8;
        box-shadow: inset 0 2em 2em 5em rgb(255 255 255);
    }

    .grille {
        display: grid;
        position: relative;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: "img" "dsc" "tbl" "btn";
        row-gap: 1em;
        background-color: #fff;
        font-size: 1.25em;
        width: 100%;
        min-width: 17em;
        border-radius: $radius-large;
        padding: 0.5rem;
        box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);

        & figure.preview {
            grid-area: img;
            position: relative;
            margin: 0 auto;
            height: 6.4em;
            box-shadow: 0 0.05em 0.2em 0 rgb(0 0 0 / 80%);

            & img {
                display: block;
                position: relative;
                width: 8em;
                height: auto;
            }

            & figcaption {
                display: inline-block;
                position: absolute;
                top: 6em;
                left: 50%;
                transform: translateX(-50%);
                padding: 0 1em;
                width: 102%;
                background-color: rgba(35, 61, 93, 90%);
                color: white;
                font-size: 0.8em;
                text-align: center;
            }
        }

        & .divider {
            width: 100%;
        }

        @media screen and (min-width: $break-medium) {
            grid-template-columns: 8em 4em auto 6em;
            grid-template-areas: "img dsc dsc dsc" "tbl tbl btn btn";
            width: 27em;
        }

        @media screen and (min-width: $break-large) {
            grid-template-columns: 8em auto 1fr auto 6em;
            grid-template-areas: "img dsc . tbl btn" "img dsc . tbl btn";
            width: 46em;
        }
    }

    .plain-table {
        display: flex;
        flex-direction: column;
        grid-area: tbl;
        margin: 0 auto;

        & table {
            padding: 0 1em 0 0;
            border-collapse: separate;
            display: inline-block;
            font-size: 0.8em;
            margin: 0 auto;

            & dt {
                @include dsc-title;
            }

            & th {
                font-size: 0.75em;
                border-bottom: 1px solid black;
            }

            & th, & td {
                padding: 0 0.5em;
            }

            & td:last-child, & th:last-child {
                border-left: 1px solid black;
                text-align: left;
            }

            & input {
                height: 1.5em;
                width: 2.5em;
                padding: 0 0 0 0.3em;
            }
        }

        @media screen and (min-width: $break-medium) {
            margin-left: 0;
            margin-top: 0;
        }
    }

    .grille-buttons {
        grid-area: btn;
        display: flex;
        flex-direction: row;
        align-self: stretch;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 0.1em;

        & button {
            font-size: 0.7em;
            justify-content: center;
            height: 2em;
            width: 100%;

            @media screen and (min-width: $break-medium) {
                width: auto;
                height: 2.5em;
            }

            @media screen and (min-width: $break-large) {
                justify-content: left;
                width: 100%;
                height: 2em;
            }
        }

        @media screen and (min-width: $break-medium) {
            flex-direction: row;
        }

        @media screen and (min-width: $break-large) {
            justify-content: center;
            flex-direction: column;
        }
    }

    .schedule-buttons {
        grid-template-columns: 1fr;
        display: grid;
        grid-gap: 0.5em;
        margin: 0 auto $column-gap auto;
        width: 100%;

        & button, & div button, & div > span.b-tooltip {
            width: 100%;
        }

        &:last-child {
            margin-top: $column-gap;
        }

        @media screen and (min-width: $break-medium) {
            width: 33.8em;
        }

        @media screen and (min-width: $break-large) {
            grid-template-columns: 1fr 1fr 1fr;
            width: 57.6em;
        }
    }

    dl {
        margin: 0 auto;

        @media screen and (min-width: $break-medium) {
            margin: 0 1em;
        }

        &.grille-description {
            display: grid;
            grid-area: dsc;
            grid-auto-flow: column;
            grid-template-rows: repeat(6, auto);
            grid-column-gap: 1em;
            margin: 0 auto;
            white-space: nowrap;
            font-size: 0.8em;

            & dt {
                margin-bottom: -0.6em
            }

            @media screen and (min-width: $break-medium) {
                width: 0;
                margin: 0 1em;
            }
        }

        & dt {
            @include dsc-title;
        }

        & dd {
            margin-bottom: 0.1em;
        }
    }



    ul.centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $column-gap;
    }
</style>