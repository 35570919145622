<template>
	<div class="section">
		<div class="container">
			<h1>Documents</h1>
			<div v-if="loading">
				<loading icon="spinner-third" spin :pulse="false"></loading>
			</div>
			<div v-else>
				<p v-if="user && !user.Profile">
					Failed to load your profile. Please try refreshing the page.
				</p>

				<div class="my-6">
					<!-- <h2>Documents</h2> -->
					<savedDocuments ref="docs"></savedDocuments>
					<o-field class="file">
						<o-upload
							v-model="file"
							accept="image/png, image/jpeg, application/pdf, application/msword"
							@input="UploadFile">
							<o-button tag="a" variant="primary" :disabled="undefined">
								<o-icon icon="upload"></o-icon>
								<span>Upload File</span>
							</o-button>
						</o-upload>
						<span class="file-name" v-if="file">{{ file.name }}</span>
					</o-field>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, onMounted, inject } from "vue";
	import useValidate from "@vuelidate/core";
	import { required, email } from "@vuelidate/validators";
	import { savedDocuments } from "@/components";
	export default {
		components: {
			savedDocuments
		},
		props: {},
		emits: [],
		setup() {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $success = inject("$success");
			const $error = inject("$error");
			const loading = ref(true);
			const states = ref([]);
			const user = ref({});
			const file = ref();
			var docs = ref();

			const v = useValidate(
				{
					user: {
						UserName: {
							required,
							email
						},
						Profile: {
							FirstName: {
								required
							},
							LastName: {
								required
							},
							Phone: {
								AtleastOne() {
									return !!user.value.Profile.Phone || !!user.value.Profile.Fax;
								}
							},
							Fax: {
								AtleastOne() {
									return !!user.value.Profile.Phone || !!user.value.Profile.Fax;
								}
							}
						}
					}
				},
				{ user }
			);

			const GetStates = async () => {
				try {
					var response = await $http.get("/api/state");
					states.value = response.data;
					return response;
				} catch (err) {
					$error("Failed to get states.");
				}
			};

			const GetUser = async () => {
				try {
					var response = await $http.get("/api/user");
					user.value = response.data;
					return response;
				} catch (err) {
					$error("Failed to get user.");
				}
			};

			const Save = async () => {
				try {
					var response = await $http.post("/api/user/admin/update", user.value);
					if (response.data.User.Id) {
						$success("Successfully updated the user's info.");
					} else {
						$error("Failed to save.");
					}
					return response;
				} catch (err) {
					$error("Failed to save.");
				}
			};
			const UploadFile = async () => {
				setTimeout(async () => {
					try {
						var formData = new FormData();
						formData.append("file", file.value);
						var response = await $http.post("/api/document/upload", formData, {
							headers: { "Content-Type": "multipart/form-data" }
						});
						file.value = null;
						// console.log(response);
						// console.log("docs ", docs.value);
						// console.log("this", this.$refs.docs);

						if (response.data.Id) {
							docs.value.GetDocuments();
							$success("The file was successfully uploaded.");
						} else {
							$error("Failed to upload file.");
						}
						return response;
					} catch (err) {
						$error("Failed to upload file.");
					}
				}, 100);
			};
			///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

			onMounted(async () => {
				await GetStates();
				await GetUser();
				loading.value = false;
				//
			});

			return {
				v,
				loading,
				states,
				user,
				file,
				docs,
				GetStates,
				GetUser,
				Save,
				UploadFile
			};
		}
	};
</script>
